import React from 'react'

import { Head } from '../../components/Head'
import { HomeExample } from '../../examples/html/pages/HomeExample'

const Page = () => (
  <>
    <Head title="Etusivu" />
    <HomeExample />
  </>
)

export default Page
